<template>
  <div>
    <!-- DONT CHANGE insurance cuz of fix in CaseView!!-->
    <case-view
        v-bind:dataTypeName="'advocardPrivateDataType'"
        v-bind:insurance="'Advocard Private'"
        v-bind:permission="'Fachberatung'"
        v-bind:rebindPermission="'Fachberatung'"
        v-bind:dataType="'Fachberatung'"
        v-bind:name="'Fachberatungen'"
        v-bind:controllerURL="controllerURLAdvocardPrivate" v-bind:allowDataEdit="false"/>
  </div>
 </template>

<script>
import CaseView from "@/components/Case/CaseView";
import {getApiURL} from "@/configBuilder";
export default {
  name: "DokumentencheckView",
  components: { CaseView },
  data: () => ({
    controllerURLAdvocardPrivate: getApiURL("AdvocardPrivateData"),
  }),
}
</script>

<style scoped>

</style>
